<template>
  <div :class="$style.progress">
    <span :style="{ width: `${value}%` }"></span>
  </div>
</template>

<script>
export default {
  name: 'TaskCommonProgress',
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" module>
.progress {
  background: var(--style-progress-bg);
  height: 0.4em;
  border-radius: 0.4em;

  span {
    content: '';
    display: block;
    height: 100%;
    background: var(--style-progress-fill);
  }
}
</style>
