<template>
  <div :class="[$style.container, $style[status]]">
    <img :class="$style.icon" :src="icon" alt="" />
    <span :class="$style.text">{{ t(status) }}</span>
  </div>
</template>

<i18n>
{
  "en": {
    "completed": "Completed",
    "paused": "Paused",
    "progress": "In progress",
    "new": "New task",
    "timeout": "Time is over"
  },
  "ru": {
    "completed": "Выполнено",
    "paused": "На паузе",
    "progress": "В процессе",
    "new": "Новое задание",
    "timeout": "Время вышло"
  }
}
</i18n>

<script>
import DoneIcon from '~/assets/images/task/status/done.svg?url'
import ProgressIcon from '~/assets/images/task/status/progress.svg?url'
import TaskIcon from '~/assets/images/task/status/task.svg?url'

export default {
  name: 'TaskCommonStatus',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case 'completed':
          return DoneIcon
        case 'progress':
          return ProgressIcon
        // case 'new': return ''
        // case 'timeout': return ''
        default:
          return TaskIcon
      }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  font-family: var(--primary-font), sans-serif;
}
.text {
  font-weight: bold;
  font-size: 1.6em;
  line-height: 120%;
  letter-spacing: -0.02em;
  white-space: nowrap;
}
.icon {
  margin-right: 0.6em;
  width: 2.2em;
  height: 2.2em;
}
.completed {
  color: #00c7b1;
  font-weight: 700;
}
.progress {
  color: #ffff00;
}
.new,
.paused {
  color: var(--app-color-text);
}
.timeout {
  color: orangered;
}
</style>
